import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_top_customers,
  get_top_customers_success,
  get_top_customers_error,
  get_total_sales,
  get_total_sales_success,
  get_total_sales_error,
  get_today_sales,
  get_today_sales_success,
  get_today_sales_error,
  get_total_customers,
  get_total_customers_success,
  get_total_customers_error,
  get_total_orders,
  get_total_orders_success,
  get_total_orders_error,
  get_top_products,
  get_top_products_success,
  get_top_products_error,
  get_recent_orders,
  get_recent_orders_success,
  get_recent_orders_error,
  get_sales_filter,
  get_sales_filter_success,
  get_sales_filter_error,
} from "./reducers";
import api from "service/DataService";

function* getTopCustomersSagas() {
  try {
    const requestRes = yield call(api.get, `/api/v1/dashBoard/customers`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_top_customers_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_top_customers_error.type,
    });
  }
}

function* getTopProductsSagas() {
  try {
    const requestRes = yield call(api.get, `/api/v1/dashBoard/product-sale`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_top_products_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_top_products_error.type,
    });
  }
}

function* getRecentOrdersSagas() {
  try {
    const requestRes = yield call(api.get, `/api/v1/dashBoard/recent-orders`);
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_recent_orders_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_recent_orders_error.type,
    });
  }
}

function* getTotalSales() {
  try {
    const request = yield call(api.get, `/api/v1/dashBoard/total-sales`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_total_sales_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_total_sales_error.type,
    });
  }
}

function* getTodaySales() {
  try {
    const request = yield call(api.get, `/api/v1/dashBoard/today-sales`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_today_sales_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_today_sales_error.type,
    });
  }
}

function* getTotalCustomersSagas() {
  try {
    const request = yield call(api.get, `/api/v1/dashBoard/total-customers`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_total_customers_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_total_customers_error.type,
    });
  }
}

function* getTotalOrderSagas() {
  try {
    const request = yield call(api.get, `/api/v1/dashBoard/total-order`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_total_orders_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_total_orders_error.type,
    });
  }
}

function* getFilterSagas({ payload }) {
  try {
    const { options, time } = payload;
    const request = yield call(
      api.get,
      `/api/v1/dashBoard/filter/${options}?time=${time}`,
    );
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_sales_filter_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_sales_filter_error.type,
    });
  }
}

function* dashboardSagas() {
  yield takeEvery(get_top_customers.type, getTopCustomersSagas);
  yield takeEvery(get_top_products.type, getTopProductsSagas);
  yield takeEvery(get_recent_orders.type, getRecentOrdersSagas);
  yield takeEvery(get_total_sales.type, getTotalSales);
  yield takeEvery(get_today_sales.type, getTodaySales);
  yield takeEvery(get_total_customers.type, getTotalCustomersSagas);
  yield takeEvery(get_total_orders.type, getTotalOrderSagas);
  yield takeEvery(get_sales_filter.type, getFilterSagas);
}

export default dashboardSagas;
