import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    fetchingReport: false,
  },
  sales_by_item: [],
  sales_report: [],
  item_sales: [],
  orders: [],
  sales_transactions: [],
};

export const reportsReducerSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    get_sales_by_item: (state) => {
      state.ui.loading = true;
    },
    get_sales_by_item_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.sales_by_item = data;
    },
    get_sales_by_item_error: (state) => {
      state.ui.loading = false;
    },
    get_sales_report: (state) => {
      state.ui.fetchingReport = true;
    },
    get_sales_report_success: (state, { payload }) => {
      state.ui.fetchingReport = false;
      const { result } = payload;
      state.sales_report = result && (result.length > 0 ? result : result.data);
    },
    get_sales_report_error: (state) => {
      state.ui.fetchingReport = false;
    },
    get_item_sales: (state) => {
      state.ui.loading = true;
    },
    get_item_sales_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.item_sales = data;
    },
    get_item_sales_error: (state) => {
      state.ui.loading = false;
    },
    get_transaction_orders: (state) => {
      state.ui.loading = true;
    },
    get_transaction_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      state.orders = payload;
    },
    get_transcation_orders_error: (state) => {
      state.ui.lodaing = false;
    },
    get_sales_transactions: (state) => {
      state.ui.loading = true;
    },
    get_sales_transactions_success: (state, { payload }) => {
      state.ui.loading = false;
      const { result } = payload;
      state.sales_transactions = result?.data ? result?.data : result;
    },
    get_sales_transactions_error: (state) => {
      state.ui.loading = false;
    },
  },
});

export const {
  get_sales_by_item,
  get_sales_by_item_success,
  get_sales_by_item_error,
  get_sales_report,
  get_sales_report_success,
  get_sales_report_error,
  get_item_sales,
  get_item_sales_success,
  get_item_sales_error,
  get_transaction_orders,
  get_transaction_orders_success,
  get_transcation_orders_error,
  get_sales_transactions,
  get_sales_transactions_success,
  get_sales_transactions_error,
} = reportsReducerSlice.actions;

export default reportsReducerSlice.reducer;
