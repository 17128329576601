import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    creating: false,
    deleting: false,
  },

  vendor: [],
  vendor_details: {},
  vendor_address: [],
  purchase_orders: [],
};

export const vendorReducerSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    get_vendors: (state) => {
      state.ui.loading = true;
    },
    get_vendors_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.vendor = data.data ? data.data : data || data.data;
    },
    get_vendors_error: (state) => {
      state.ui.loading = false;
    },
    create_vendors: (state) => {
      state.ui.creating = true;
    },
    create_vendors_success: (state) => {
      state.ui.creating = false;
    },
    create_vendors_error: (state) => {
      state.ui.creating = false;
    },
    update_vendor: (state) => {
      state.ui.creating = true;
    },
    update_vendor_success: (state) => {
      state.ui.creating = false;
    },
    update_vendor_error: (state) => {
      state.ui.creating = false;
    },
    get_vendor: (state) => {
      state.ui.loading = true;
    },
    get_vendor_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.vendor_details = data.vendor[0];
      state.vendor_address = data.address;
    },
    get_vendor_error: (state) => {
      state.ui.loading = false;
    },
    delete_vendor_address: (state) => {
      state.ui.deleting = true;
    },
    delete_vendor_address_success: (state) => {
      state.ui.deleting = false;
    },
    delete_vendor_address_error: (state) => {
      state.ui.loading = false;
    },
    add_vendor_address: (state) => {
      state.ui.creating = true;
    },
    add_vendor_address_success: (state) => {
      state.ui.creating = false;
    },
    add_vendor_address_error: (state) => {
      state.ui.creating = false;
    },
    update_vendor_address: (state) => {
      state.ui.creating = true;
    },
    update_vendor_address_success: (state) => {
      state.ui.creating = false;
    },
    update_vendor_address_error: (state) => {
      state.ui.creating = false;
    },
    get_vendors_purchase: (state) => {
      state.ui.loading = true;
    },
    get_vendors_purchase_success: (state, { payload }) => {
      state.ui.loading = false;
      state.purchase_orders = payload;
    },
    get_vendors_purchase_error: (state) => {
      state.ui.loading = false;
    },
  },
});

export const {
  get_vendors,
  get_vendors_success,
  get_vendors_error,
  create_vendors,
  create_vendors_success,
  create_vendors_error,
  get_vendor,
  get_vendor_success,
  get_vendor_error,
  delete_vendor_address,
  delete_vendor_address_success,
  delete_vendor_address_error,
  add_vendor_address,
  add_vendor_address_success,
  add_vendor_address_error,
  update_vendor_address,
  update_vendor_address_success,
  update_vendor_address_error,
  update_vendor,
  update_vendor_success,
  update_vendor_error,
  get_vendors_purchase,
  get_vendors_purchase_success,
  get_vendors_purchase_error,
} = vendorReducerSlice.actions;

export default vendorReducerSlice.reducer;
