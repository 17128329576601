import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_discounts,
  get_discounts_success,
  get_discounts_error,
  auto_create,
  auto_create_success,
  auto_create_error,
  search_discounts,
  search_discounts_success,
  search_discounts_error,
  filter_discount,
  filter_discount_success,
  filter_discount_error,
  add_discount,
  add_discount_success,
  add_discount_error,
  update_discount,
  update_discount_success,
  update_discount_error,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getDiscountSaga() {
  try {
    const requestRes = yield call(api.get, `/api/v1/discounts`);
    const response = requestRes.data;
    yield put({
      type: get_discounts_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_discounts_error.type,
    });
  }
}

function* autoCreateDiscountSaga() {
  try {
    const requestRes = yield call(api.post, `/api/v1/discounts/auto-create`);
    const response = requestRes.data;
    yield put({
      type: auto_create_success.type,
      payload: response,
    });
    console.log(response);
    // window.location.href = "/discounts";
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: auto_create_error.type,
    });
  }
}

function* searchDiscountSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/discounts/search/${payload}`,
    );
    const response = requestRes.data;
    yield put({
      type: search_discounts_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: search_discounts_error.type,
    });
  }
}

function* filterDiscountSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/discounts/filter/${payload}`,
    );
    const response = requestRes.data;
    yield put({
      type: filter_discount_success.type,
      payload: response,
    });
  } catch (error) {
    const { response } = error;
    console.log(response.data);
    yield put({
      type: filter_discount_error.type,
      payload: response,
    });
  }
}

function* addDiscountSaga({ payload }) {
  try {
    const requestRes = yield call(api.post, `/api/v1/discounts`, payload);
    const response = requestRes.data;
    yield put({
      type: add_discount_success.type,
      payload: response,
    });
    toast.success("Discount added successfully", {
      style: {
        background: "#10AA69",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
    setTimeout(() => {
      window.location.href = "/sales/discounts";
    }, 1500);
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: add_discount_error.type,
    });
  }
}

function* updateDiscountSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.patch,
      `/api/v1/discounts/${payload.id}`,
      payload.data,
    );
    const response = requestRes.data;
    yield put({
      type: update_discount_success.type,
      payload: response.message,
    });
    toast.success(response.message);

    setTimeout(() => {
      window.location.href = "/sales/discounts";
    }, 1500);
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: update_discount_error.type,
    });
  }
}

function* discountSaga() {
  yield takeEvery(get_discounts.type, getDiscountSaga);
  yield takeEvery(auto_create.type, autoCreateDiscountSaga);
  yield takeEvery(search_discounts.type, searchDiscountSaga);
  yield takeEvery(filter_discount.type, filterDiscountSaga);
  yield takeEvery(add_discount.type, addDiscountSaga);
  yield takeEvery(update_discount.type, updateDiscountSaga);
}

export default discountSaga;
