import { all } from "redux-saga/effects";
import authSagas from "./auth/redux/sagas";
import customerSagas from "./customers/redux/sagas";
import dashboardSagas from "./dashboard/redux/sagas";
import developerSagas from "./developers/redux/sagas";
import discountSaga from "./discounts/redux/sagas";
import ordersSagas from "./orders/redux/sagas";
import productsSagas from "./products/redux/sagas";
import reportSaga from "./report/redux/sagas";
import settingsSagas from "./settings/redux/sagas";
import storeSagas from "./store/redux/sagas";
import vendorsSagas from "./vendors/redux/sagas";
import walletSagas from "./wallet/redux/sagas";
import purchaseOrdersSagas from "./purchaseOrders/redux/sagas";
import paymentReceivedSagas from "./paymentReceived/redux/sagas";

export default function* root() {
  yield all([
    authSagas(),
    customerSagas(),
    settingsSagas(),
    productsSagas(),
    discountSaga(),
    ordersSagas(),
    developerSagas(),
    reportSaga(),
    storeSagas(),
    dashboardSagas(),
    vendorsSagas(),
    walletSagas(),
    purchaseOrdersSagas(),
    paymentReceivedSagas(),
  ]);
}
