import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    creating: false,
    saving: false,
  },
  store: [],
};

export const storeReducerSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    get_stores: (state) => {
      state.ui.loading = true;
    },
    get_stores_success: (state, { payload }) => {
      state.ui.loading = false;
      state.store = payload;
    },
    get_stores_error: (state) => {
      state.ui.loading = false;
    },
    create_store: (state) => {
      state.ui.loading = true;
    },
    create_store_success: (state) => {
      state.ui.loading = false;
    },
    create_store_error: (state) => {
      state.ui.loading = false;
    },
    update_store_details: (state) => {
      state.ui.saving = true;
    },

    update_store_details_success: (state) => {
      state.ui.saving = false;
    },
    update_store_details_error: (state) => {
      state.ui.saving = false;
    },
    update_store_currency: (state) => {
      state.ui.saving = true;
    },

    update_store_currency_success: (state) => {
      state.ui.saving = false;
    },
    update_store_currency_error: (state) => {
      state.ui.saving = false;
    },
    publish_store: (state) => {
      state.ui.creating = true;
    },
    publish_store_success: (state) => {
      state.ui.creating = false;
    },
    publish_store_error: (state) => {
      state.ui.creating = false;
    },
  },
});

export const {
  get_stores,
  get_stores_success,
  get_stores_error,
  create_store,
  create_store_success,
  create_store_error,
  update_store_details,
  update_store_details_success,
  update_store_details_error,
  update_store_currency,
  update_store_currency_success,
  update_store_currency_error,
  publish_store,
  publish_store_success,
  publish_store_error,
} = storeReducerSlice.actions;

export default storeReducerSlice.reducer;
