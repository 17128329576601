import { put, takeEvery, takeLatest, call } from "@redux-saga/core/effects";
import {
  get_user,
  get_user_success,
  get_user_error,
  get_members,
  get_members_success,
  get_members_error,
  add_member,
  add_member_success,
  add_member_error,
  change_role,
  change_role_success,
  change_role_error,
  remove_member,
  remove_member_success,
  remove_member_error,
  get_all_audit_logs,
  get_all_audit_logs_success,
  get_all_audit_logs_error,
  get_audit_log,
  get_audit_log_success,
  get_audit_log_error,
  get_permissions,
  get_permissions_success,
  get_permissions_error,
  create_role,
  create_role_success,
  create_role_error,
  search_audit_logs,
  search_audit_logs_success,
  search_audit_logs_error,
  get_roles,
  get_roles_success,
  get_roles_error,
  update_user_details_success,
  update_user_details_error,
  change_user_password,
  change_user_password_success,
  change_user_password_error,
  update_user_details,
  update_billing_contact,
  update_billing_contact_success,
  update_billing_contact_error,
  update_user_website,
  update_user_website_success,
  update_user_website_error,
  get_subscriptions,
  get_subscriptions_success,
  get_subscriptions_error,
  subscribe,
  subscribe_success,
  subscribe_error,
  get_plan,
  get_plan_success,
  get_plan_error,
  change_personal_image,
  change_personal_image_success,
  change_personal_image_error,
  change_brand_image,
  change_brand_image_success,
  change_brand_image_error,
  get_all_user_plan,
  get_all_user_plan_success,
  get_all_user_plan_error,
  get_banks,
  get_banks_success,
  get_banks_error,
  verify_account,
  verify_account_success,
  verify_account_error,
  create_shipping,
  create_shipping_success,
  create_shipping_error,
  get_shipping,
  get_shipping_success,
  get_shipping_error,
  update_shipping,
  update_shipping_success,
  update_shipping_error,
  create_tax,
  create_tax_success,
  create_tax_error,
  get_tax,
  get_tax_success,
  get_tax_error,
  update_tax,
  update_tax_success,
  update_tax_error,
  delete_tax,
  delete_tax_success,
  delete_tax_error,
  delete_shipping,
  delete_shipping_success,
  delete_shipping_error,
  update_store_address,
  update_store_address_success,
  update_store_address_error,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getUserSaga({ payload }) {
  try {
    const requestRes = yield call(api.post, `/api/v1/user/singleuser`, payload);
    const response = requestRes.data;
    yield put({
      type: get_user_success.type,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: get_user_error.type,
    });
  }
}

function* getMembersSaga() {
  try {
    const requestRes = yield call(api.get, `/api/v1/members/all-members`);
    const response = requestRes.data;
    yield put({
      type: get_members_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_members_error.type,
    });
  }
}

function* addMemberSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.post,
      `/api/v1/members/add-member`,
      payload,
    );
    const response = requestRes.data;

    yield put({
      type: add_member_success.type,
      payload: response.data,
    });
    toast.success(response.message, {
      style: {
        background: "#10AA69",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.msg);
    yield put({
      type: add_member_error.type,
    });
  }
}

function* changeRoleSaga({ payload }) {
  try {
    const { id, role } = payload;
    const requestRes = yield call(
      api.post,
      `api/v1/members/change-role/${id}`,
      role,
    );
    const response = requestRes.data;
    yield put({
      type: change_role_success.type,
    });
    toast.success(response.data, {
      style: {
        background: "#10AA69",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    yield put({
      type: change_role_error.type,
    });
  }
}

function* removeMemberSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.delete,
      `api/v1/members/delete-member/${payload}`,
    );
    const response = requestRes.data;

    yield put({
      type: remove_member_success.type,
      payload: payload,
    });
    toast.error(response.data, {
      style: {
        background: "#F13E3E",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
  } catch (error) {
    toast.error(error.response.data.data);

    yield put({
      type: remove_member_error.type,
    });
  }
}

function* getAllAuditLogsSaga() {
  try {
    const request = yield call(api.get, `api/v1/audits`);
    const response = request.data;
    yield put({
      type: get_all_audit_logs_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    console.log(error.response.data.data);
    yield put({
      type: get_all_audit_logs_error.type,
    });
  }
}

function* getAuditLogSaga({ payload }) {
  try {
    const request = yield call(api.get, `api/v1/audits/${payload}`);
    const response = request.data;
    yield put({
      type: get_audit_log_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_audit_log_error.type,
    });
  }
}

function* searchAuditLogSaga({ payload }) {
  try {
    const request = yield call(api.get, `api/v1/audits/search/${payload}`);
    const response = request.data;
    yield put({
      type: search_audit_logs_success.type,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: search_audit_logs_error.type,
    });
  }
}

function* getPermissionsSaga() {
  try {
    const request = yield call(api.get, `api/v1/permissions`);
    const response = request.data;
    yield put({
      type: get_permissions_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    // toast.error(`Permission Error - ${error.response.data.data}`);
    yield put({
      type: get_permissions_error.type,
    });
  }
}

function* createRoleSaga({ payload }) {
  try {
    const requestRes = yield call(api.post, `/api/v1/roles`, payload);
    const response = requestRes.data;
    toast.success("Role created successfully");
    yield put({
      type: create_role_success.type,
    });
    console.log(response);
  } catch (error) {
    yield put({
      type: create_role_error.type,
    });
  }
}

function* getRolesSaga() {
  try {
    const requestRes = yield call(api.get, `/api/v1/roles`);
    const response = requestRes.data;
    yield put({
      type: get_roles_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_roles_error.type,
    });
  }
}

function* getUserPlan({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/subscription/user?storeId=${payload}`,
    );
    const response = requestRes.data;
    yield put({
      type: get_plan_success.type,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: get_plan_error.type,
    });
  }
}

function* updateUserSaga({ payload }) {
  try {
    const request = yield call(
      api.patch,
      `/api/v1/user/update-user-details`,
      payload,
    );
    const response = request.data;
    yield put({
      type: update_user_details_success.type,
      payload: response,
    });
    toast.success(response.data, {
      style: {
        background: "#10AA69",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
    setTimeout(() => {
      window.location.href = "/settings";
    }, 1500);
  } catch (error) {
    console.log(error);
    yield put({
      type: update_user_details_error.type,
    });
  }
}

function* changeUserPassword({ payload }) {
  try {
    const request = yield call(
      api.patch,
      `/api/v1/user/update-user-password`,
      payload,
    );
    const response = request.data;
    yield put({
      type: change_user_password_success.type,
      payload: response,
    });
    if (response.success) {
      toast.success(response.data, {
        style: {
          background: "#10AA69",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "500",
          height: "64px",
        },
      });
    }
  } catch (error) {
    toast.error(error.response.data.error);
    yield put({
      type: change_user_password_error.type,
    });
  }
}

function* updateUserWebsiteSaga({ payload }) {
  try {
    const request = yield call(
      api.patch,
      `/api/v1/user/insert-website`,
      payload,
    );
    const response = request.data;
    yield put({
      type: update_user_website_success.type,
      payload: response,
    });
    if (response.success) {
      toast.success(response.data, {
        style: {
          background: "#10AA69",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "500",
          height: "64px",
        },
      });
      setTimeout(() => {
        window.location.href = "/settings";
      }, 1500);
    }
  } catch (error) {
    toast.error(error.response.data.error);
    yield put({
      type: update_user_website_error.type,
    });
  }
}

function* updateBillingContactSaga({ payload }) {
  try {
    const request = yield call(
      api.patch,
      `/api/v1/user/billing-contact`,
      payload,
    );
    const response = request.data;
    yield put({
      type: update_billing_contact_success.type,
      payload: response,
    });
    if (response.success) {
      toast.success(response.data, {
        style: {
          background: "#10AA69",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "500",
          height: "64px",
        },
      });
      setTimeout(() => {
        window.location.href = "/settings";
      }, 1500);
    }
  } catch (error) {
    toast.error(error.response.data.error);
    yield put({
      type: update_billing_contact_error.type,
    });
  }
}

function* getSubscriptions() {
  try {
    const request = yield call(api.get, `/api/v1/subscription`);
    const response = request.data;
    yield put({
      type: get_subscriptions_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_subscriptions_error.type,
    });
  }
}

function* Subscribe({ payload }) {
  try {
    const { data, id } = payload;
    const request = yield call(
      api.post,
      `/api/v1/subscription/subscribe?storeId=${id}`,
      data,
    );
    const response = request.data;
    yield put({
      type: subscribe_success.type,
      payload: response,
    });
    toast.success("Subscribed!!!!", {
      style: {
        background: "#10AA69",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: subscribe_error.type,
    });
  }
}

function* uploadPersonalImage({ payload }) {
  try {
    const request = yield call(api.patch, `/api/v1/user/photo`, payload);
    const response = request.data;
    console.log(response);
    yield put({
      type: change_personal_image_success.type,
      payload: response,
    });
    toast.success(response.data);
  } catch (error) {
    yield put({
      type: change_personal_image_error.type,
    });
  }
}

function* uploadBrandImage({ payload }) {
  try {
    const request = yield call(api.patch, `/api/v1/user/brand-photo`, payload);
    const response = request.data;
    console.log(response);
    yield put({
      type: change_brand_image_success.type,
      payload: response,
    });
    toast.success(response.data);
  } catch (error) {
    yield put({
      type: change_brand_image_error.type,
    });
  }
}

function* getAllUserPlanSagas() {
  try {
    const request = yield call(api.get, `/api/v1/subscription/all`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_all_user_plan_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_all_user_plan_error.type,
    });
  }
}

function* getBanksSagas() {
  try {
    const request = yield call(api.get, `/api/v1/payment/banks`);
    const response = request.data;
    const { data } = response;
    yield put({
      type: get_banks_success.type,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_banks_error.type,
    });
  }
}

function* verifyAccountSagas({ payload }) {
  try {
    const request = yield call(
      api.get,
      `/api/v1/payment/verify-account?accountNumber=${payload.accountNumber}&bankCode=${payload.bankCode}`,
    );
    const response = request.data;
    const { data } = response;
    yield put({
      type: verify_account_success.type,
      payload: data,
    });
    toast.success("Saved!!");
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.error);
    yield put({
      type: verify_account_error.type,
    });
  }
}

function* createShippingZoneSaga({ payload }) {
  try {
    const { data, prev } = payload;
    const request = yield call(api.post, `/api/v1/shipping`, data);
    const response = request.data;
    yield put({
      type: create_shipping_success.type,
    });
    toast.success(response.message);
    setTimeout(() => {
      prev();
    }, 1000);
  } catch (err) {
    toast.error(err?.message);
    yield put({
      type: create_shipping_error.type,
    });
  }
}

function* getShippingSaga({ payload }) {
  try {
    const request = yield call(api.get, `/api/v1/shipping/store/${payload}`);
    const response = request.data;
    yield put({
      type: get_shipping_success.type,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: get_shipping_error.type,
    });
  }
}

function* updateShippingSaga({ payload }) {
  try {
    const { data, id } = payload;
    const request = yield call(
      api.patch,
      `/api/v1/shipping/update/${id}`,
      data,
    );
    const response = request.data;
    yield put({
      type: update_shipping_success.type,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.href = "/settings/shipping";
    }, 1000);
  } catch (err) {
    console.log(err);
    yield put({
      type: update_shipping_error.type,
    });
  }
}

function* deleteShippingSaga({ payload }) {
  try {
    const request = yield call(
      api.delete,
      `/api/v1/shipping/delete/${payload}`,
    );
    const response = request.data;
    yield put({
      type: delete_shipping_success.type,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.href = "/settings/shipping";
    }, 1000);
  } catch (error) {
    yield put({
      type: delete_shipping_error.type,
    });
  }
}

function* createTaxSaga({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/tax`, payload);
    const response = request.data;
    yield put({
      type: create_tax_success.type,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.href = "/settings/tax";
    }, 1000);
  } catch (error) {
    console.log(error);
    yield put({
      type: create_tax_error.type,
    });
  }
}

function* getTaxSaga({ payload }) {
  try {
    const request = yield call(api.get, `/api/v1/tax/store/${payload}`);
    const response = request.data;
    yield put({
      type: get_tax_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_tax_error.type,
    });
  }
}

function* updateTaxSaga({ payload }) {
  try {
    const { data, id } = payload;
    const request = yield call(api.patch, `/api/v1/tax/update/${id}`, data);
    const response = request.data;
    toast.success(response.message);
    yield put({
      type: update_tax_success.type,
    });

    setTimeout(() => {
      window.location.href = "/settings/tax";
    }, 1000);
  } catch (err) {
    console.log(err);
    yield put({
      type: update_tax_error.type,
    });
  }
}

function* deleteTaxSaga({ payload }) {
  try {
    const request = yield call(api.delete, `/api/v1/tax/delete/${payload}`);
    const response = request.data;
    yield put({
      type: delete_tax_success.type,
    });
    toast.success(response?.message);
    setTimeout(() => {
      window.location.href = "/settings/tax";
    }, 1000);
  } catch (err) {
    console.log(err);
    yield put({
      type: delete_tax_error.type,
    });
  }
}

function* updateStoreAddressSaga({ payload }) {
  try {
    const request = yield call(api.patch, `/api/v1/stores/address`, payload);

    const response = request.data;
    yield put({
      type: update_store_address_success.type,
    });
    toast.success(response.data);
    setTimeout(() => {
      window.location.href = "/settings/shipping";
    }, 1000);
  } catch (error) {
    console.log(error);
    yield put({
      type: update_store_address_error.type,
    });
  }
}

function* settingsSagas() {
  yield takeEvery(get_members.type, getMembersSaga);
  yield takeEvery(get_user.type, getUserSaga);
  yield takeLatest(add_member.type, addMemberSaga);
  yield takeLatest(change_role.type, changeRoleSaga);
  yield takeLatest(remove_member.type, removeMemberSaga);
  yield takeLatest(search_audit_logs.type, searchAuditLogSaga);
  yield takeLatest(create_role.type, createRoleSaga);
  yield takeEvery(get_all_audit_logs.type, getAllAuditLogsSaga);
  yield takeEvery(get_audit_log.type, getAuditLogSaga);
  yield takeEvery(get_permissions.type, getPermissionsSaga);
  yield takeEvery(get_roles.type, getRolesSaga);
  yield takeLatest(update_user_details.type, updateUserSaga);
  yield takeLatest(change_user_password.type, changeUserPassword);
  yield takeLatest(update_billing_contact.type, updateBillingContactSaga);
  yield takeLatest(update_user_website.type, updateUserWebsiteSaga);
  yield takeEvery(get_subscriptions.type, getSubscriptions);
  yield takeEvery(subscribe.type, Subscribe);
  yield takeEvery(get_plan.type, getUserPlan);
  yield takeEvery(change_personal_image.type, uploadPersonalImage);
  yield takeEvery(change_brand_image.type, uploadBrandImage);
  yield takeEvery(get_all_user_plan.type, getAllUserPlanSagas);
  yield takeEvery(get_banks.type, getBanksSagas);
  yield takeEvery(verify_account.type, verifyAccountSagas);
  yield takeEvery(create_shipping.type, createShippingZoneSaga);
  yield takeEvery(get_shipping.type, getShippingSaga);
  yield takeEvery(update_shipping.type, updateShippingSaga);
  yield takeEvery(create_tax.type, createTaxSaga);
  yield takeEvery(get_tax.type, getTaxSaga);
  yield takeEvery(update_tax.type, updateTaxSaga);
  yield takeEvery(delete_tax.type, deleteTaxSaga);
  yield takeEvery(delete_shipping.type, deleteShippingSaga);
  yield takeEvery(update_store_address.type, updateStoreAddressSaga);
}

export default settingsSagas;
