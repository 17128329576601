import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    creating: false,
  },
  orders: [],
  stats: {},
  order: {},
  scanned: [],
};

export const ordersReducerSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    get_orders: (state) => {
      state.ui.loading = true;
    },
    get_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      const { orders, pagination } = payload;
      state.orders = orders;
      state.stats = { ...pagination };
    },
    get_orders_error: (state) => {
      state.ui.loading = false;
    },
    filter_orders: (state) => {
      state.ui.loading = true;
    },
    filter_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.orders = data;
    },
    filter_orders_error: (state) => {
      state.ui.loading = false;
    },
    search_orders: (state) => {
      state.ui.loading = true;
    },
    search_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      const { orders } = payload;
      state.orders = orders;
    },
    search_orders_error: (state) => {
      state.ui.loading = false;
    },
    create_order: (state) => {
      state.ui.loading = true;
    },
    create_order_success: (state) => {
      state.ui.loading = false;
    },
    create_order_error: (state) => {
      state.ui.loading = false;
    },
    get_order: (state) => {
      state.ui.loading = true;
    },
    get_order_success: (state, { payload }) => {
      state.ui.loading = false;
      state.order = payload;
    },
    get_order_error: (state) => {
      state.ui.loading = false;
    },
    update_order_status: (state) => {
      state.ui.creating = true;
    },
    update_order_status_success: (state) => {
      state.ui.creating = false;
    },
    update_order_status_error: (state) => {
      state.ui.creating = false;
    },
    get_scanned_item: (state) => {
      state.ui.creating = true;
    },
    get_scanned_item_success: (state, { payload }) => {
      state.ui.creating = false;
      console.log(payload);
      const items = [...state.scanned];

      const existingItem = items.findIndex((item) => item.id === payload[0].id);

      if (existingItem !== -1) {
        items[existingItem] = {
          ...items[existingItem],
          quantity: items[existingItem].quantity + payload[0].quantity,
        };
      } else {
        items.push(payload[0]);
      }

      console.log(items);
    },
    get_scanned_item_error: (state) => {
      state.ui.creating = false;
    },
  },
});

export const {
  get_orders,
  get_orders_success,
  get_orders_error,
  filter_orders,
  filter_orders_success,
  filter_orders_error,
  search_orders,
  search_orders_success,
  search_orders_error,
  create_order,
  create_order_success,
  create_order_error,
  get_order,
  get_order_success,
  get_order_error,
  update_order_status,
  update_order_status_success,
  update_order_status_error,
  get_scanned_item,
  get_scanned_item_success,
  get_scanned_item_error,
} = ordersReducerSlice.actions;

export default ordersReducerSlice.reducer;
