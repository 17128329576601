import { put, takeEvery, takeLatest, call } from "@redux-saga/core/effects";
import {
  login_success,
  login_error,
  login,
  sign_up_success,
  sign_up_error,
  sign_up,
  forgot_password_success,
  forgot_password_error,
  forgot_password,
  reset_password_success,
  reset_password_error,
  reset_password,
  verify_business,
  verify_business_success,
  verify_business_error,
  verify_numbers,
  verify_numbers_success,
  verify_numbers_error,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";
import { setWithExpiry } from "utils/store";
import * as amplitude from "@amplitude/analytics-browser";

function* loginSaga({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/user/signin`, payload, {
      timeout: 20000,
    });
    const productionData = request.data;
    setWithExpiry("l-access-token", productionData.data.userToken);
    setWithExpiry("r-email", productionData.data.email);
    amplitude.track("login");
    amplitude.setUserId(productionData.data.userToken);
    toast.success(productionData.message);
    yield put({
      type: login_success.type,
      payload: productionData.data,
    });

    setTimeout(() => {
      window.location.href = "/home";
    }, 500);
  } catch (error) {
    const errorMessage =
      error?.response?.data?.error?.message ||
      error?.response?.data?.message ||
      error?.message;
    toast.error(errorMessage);
    console.log(error);

    yield put({
      type: login_error.type,
    });
  }
}

function* SignUpSaga({ payload }) {
  try {
    const { data, onClose } = payload;
    const staging = yield call(api.post, `/api/v1/user/signup`, data);
    const responseData = staging.data;
    yield put({
      type: sign_up_success.type,
      payload: responseData,
    });
    amplitude.track("Sign up");
    setTimeout(() => {
      onClose();
    }, 500);
  } catch (error) {
    if (error) {
      toast.error(
        error?.response?.status === 400
          ? error?.response?.data?.body?.phone_number
          : error?.response?.data?.error,
      );
      console.log(error);
      yield put({
        type: sign_up_error.type,
      });
    }
  }
}

function* forgotPasswordSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.patch,
      `/api/v1/user/forgot-password`,
      payload,
    );
    const responseData = requestRes.data;
    const response = responseData.data;

    yield put({
      type: forgot_password_success.type,
      payload: response,
    });

    window.location.href = "/email-otp";
  } catch (error) {
    toast.error(error?.response?.data?.error);
    yield put({
      type: forgot_password_error.type,
    });
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    const { id, data } = payload;
    const requestRes = yield call(
      api.patch,
      `/api/v1/user/reset-password/${id}`,
      data,
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: reset_password_success.type,
      payload: response,
    });
    window.location.href = "/sign-in";
  } catch (error) {
    toast.error(error.response.data.error);
    yield put({
      type: reset_password_error.type,
    });
  }
}

function* verifyBusinessInfo({ payload }) {
  try {
    const { id, data } = payload;
    const production = yield call(
      api.put,
      `/api/v1/user/business-info/${id}`,
      data,
    );
    const response = production.data;
    console.log(response);
    yield put({
      type: verify_business_success.type,
    });
    toast.success("Completed!!");
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  } catch (error) {
    toast.error(error?.response?.data?.error);
    yield put({
      type: verify_business_error.type,
    });
  }
}

function* verifyNumbers({ payload }) {
  try {
    const { id, data } = payload;
    const request = yield call(
      api.put,
      `/api/v1/user/business-number/${id}`,
      data,
    );
    const response = request.data;
    console.log(response);
    setTimeout(() => {
      window.location.href = "/home";
    }, 1000);
    yield put({
      type: verify_numbers_success,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: verify_numbers_error.type,
    });
  }
}

function* authSagas() {
  yield takeEvery(login.type, loginSaga);
  yield takeLatest(sign_up.type, SignUpSaga);
  yield takeLatest(forgot_password.type, forgotPasswordSaga);
  yield takeLatest(reset_password.type, resetPasswordSaga);
  yield takeLatest(verify_business, verifyBusinessInfo);
  yield takeLatest(verify_numbers, verifyNumbers);
}

export default authSagas;
