import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    loading_bank: false,
    validate_bank: false,
    transfer: false,
  },
  details: {},
  user_transactions: [],
  validated: {},
  banks: [],
};

export const walletReducerSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    get_wallet_details: (state) => {
      state.ui.loading = true;
    },
    get_wallet_details_success: (state, { payload }) => {
      state.ui.loading = false;
      const { accountDetails } = payload;
      state.details = accountDetails;
    },
    get_wallet_details_error: (state) => {
      state.ui.loading = false;
    },
    create_wallet: (state) => {
      state.ui.loading = true;
    },
    create_wallet_success: (state) => {
      state.ui.loading = false;
    },
    create_wallet_error: (state) => {
      state.ui.loading = false;
    },
    get_user_transactions: (state) => {
      state.ui.loading = true;
    },
    get_user_transactions_success: (state, { payload }) => {
      state.ui.loading = false;
      state.user_transactions = payload;
    },
    get_user_transactions_error: (state) => {
      state.ui.loading = false;
    },
    validate_account: (state) => {
      state.ui.validate_bank = true;
    },
    validate_account_success: (state, { payload }) => {
      state.ui.validate_bank = false;
      const { data } = payload;
      state.validated = data;
    },
    validate_account_error: (state) => {
      state.ui.validate_bank = false;
    },
    get_banks: (state) => {
      state.ui.loading_bank = true;
    },
    get_banks_success: (state, { payload }) => {
      state.ui.loading_bank = false;
      state.banks = payload.codes;
    },
    get_banks_error: (state) => {
      state.ui.loading_bank = false;
    },
    transfer_to_banks: (state) => {
      state.ui.transfer = true;
    },
    transfer_to_banks_success: (state) => {
      state.ui.transfer = false;
    },
    transfer_to_banks_error: (state) => {
      state.ui.transfer = false;
    },
  },
});

export const {
  get_wallet_details,
  get_wallet_details_error,
  get_wallet_details_success,
  create_wallet,
  create_wallet_success,
  create_wallet_error,
  get_user_transactions,
  get_user_transactions_success,
  get_user_transactions_error,
  validate_account,
  validate_account_error,
  validate_account_success,
  get_banks,
  get_banks_success,
  get_banks_error,
  transfer_to_banks,
  transfer_to_banks_success,
  transfer_to_banks_error,
} = walletReducerSlice.actions;

export default walletReducerSlice.reducer;
