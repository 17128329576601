import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_vendors,
  get_vendors_success,
  get_vendors_error,
  create_vendors,
  create_vendors_success,
  create_vendors_error,
  get_vendor,
  get_vendor_success,
  get_vendor_error,
  delete_vendor_address,
  delete_vendor_address_success,
  delete_vendor_address_error,
  add_vendor_address,
  add_vendor_address_success,
  add_vendor_address_error,
  update_vendor_address,
  update_vendor_address_success,
  update_vendor_address_error,
  update_vendor,
  update_vendor_success,
  update_vendor_error,
  get_vendors_purchase,
  get_vendors_purchase_success,
  get_vendors_purchase_error,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getVendors({ payload }) {
  try {
    const { filter } = payload;
    let status = ``;

    if (filter !== "") {
      status = `?status=${filter}`;
    } else {
      status = ``;
    }
    const request = yield call(api.get, `/api/v1/vendor${status}`);
    const response = request.data;
    yield put({
      type: get_vendors_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);

    yield put({
      type: get_vendors_error.type,
    });
  }
}

function* createVendors({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/vendor`, payload);
    const response = request.data;
    yield put({
      type: create_vendors_success.type,
    });

    if (response.success === false) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(() => {
        window.location.href = "/purchase/vendors";
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: create_vendors_error.type,
    });
  }
}

function* updateVendors({ payload }) {
  try {
    const { id, data } = payload;
    const request = yield call(api.patch, `/api/v1/vendor/update/${id}`, data);
    const response = request.data;
    yield put({
      type: update_vendor_success.type,
    });

    if (response.success === false) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(() => {
        window.location.href = "/purchase/vendors";
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: update_vendor_error.type,
    });
  }
}

function* getVendor({ payload }) {
  try {
    const request = yield call(api.get, `/api/v1/vendor/${payload}`);
    const response = request.data;
    yield put({
      type: get_vendor_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_vendor_error.type,
    });
  }
}

function* deleteVendorAddress({ payload }) {
  try {
    const request = yield call(
      api.delete,
      `/api/v1/vendor/delete/address/${payload}`,
    );
    const response = request.data;
    yield put({
      type: delete_vendor_address_success.type,
      payload: response,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    console.log(err);

    yield put({
      type: delete_vendor_address_error.type,
    });
  }
}

function* addVendorAddress({ payload }) {
  try {
    const { id, data } = payload;
    const request = yield call(
      api.post,
      `/api/v1/vendor/${id}/addresses`,
      data,
    );
    const response = request.data;
    yield put({
      type: add_vendor_address_success.type,
      payload: response,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    console.log(err);

    yield put({
      type: add_vendor_address_error.type,
    });
  }
}

function* updateVendorAddress({ payload }) {
  try {
    const { id, data } = payload;
    const request = yield call(
      api.patch,
      `/api/v1/vendor/update/address/${id}`,
      data,
    );
    const response = request.data;
    yield put({
      type: update_vendor_address_success.type,
      payload: response,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    console.log(err);

    yield put({
      type: update_vendor_address_error.type,
    });
  }
}

function* getVendorPurchase({ payload }) {
  try {
    const request = yield call(
      api.get,
      `/api/v1/purchase_order/vendor/${payload}`,
    );
    const response = request.data;
    console.log(response);
    yield put({
      type: get_vendors_purchase_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_vendors_purchase_error.type,
    });
  }
}

function* vendorsSagas() {
  yield takeEvery(get_vendors.type, getVendors);
  yield takeEvery(get_vendor.type, getVendor);
  yield takeEvery(delete_vendor_address.type, deleteVendorAddress);
  yield takeEvery(add_vendor_address.type, addVendorAddress);
  yield takeEvery(update_vendor_address.type, updateVendorAddress);
  yield takeEvery(create_vendors.type, createVendors);
  yield takeEvery(update_vendor.type, updateVendors);
  yield takeEvery(get_vendors_purchase.type, getVendorPurchase);
}

export default vendorsSagas;
