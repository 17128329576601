import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    creating: false,
  },
  discount: [],
};

export const discountReducerSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    get_discounts: (state) => {
      state.ui.loading = true;
    },
    get_discounts_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.discount = data;
    },
    get_discounts_error: (state) => {
      state.ui.loading = false;
    },
    auto_create: (state) => {
      state.ui.creating = true;
    },
    auto_create_success: (state, { payload }) => {
      state.ui.creating = false;
    },
    auto_create_error: (state) => {
      state.ui.creating = false;
    },
    search_discounts: (state) => {
      state.ui.loading = true;
    },
    search_discounts_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.discount = data;
    },
    search_discounts_error: (state) => {
      state.ui.loading = false;
    },
    filter_discount: (state) => {
      state.ui.loading = true;
    },
    filter_discount_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.discount = data;
    },
    filter_discount_error: (state) => {
      state.ui.loading = false;
    },
    add_discount: (state) => {
      state.ui.creating = true;
    },
    add_discount_success: (state) => {
      state.ui.creating = false;
    },
    add_discount_error: (state) => {
      state.ui.creating = false;
    },
    update_discount: (state) => {
      state.ui.creating = true;
    },
    update_discount_success: (state) => {
      state.ui.creating = false;
    },
    update_discount_error: (state) => {
      state.ui.creating = false;
    },
  },
});

export const {
  get_discounts,
  get_discounts_success,
  get_discounts_error,
  auto_create,
  auto_create_success,
  auto_create_error,
  search_discounts,
  search_discounts_success,
  search_discounts_error,
  filter_discount,
  filter_discount_success,
  filter_discount_error,
  add_discount,
  add_discount_success,
  add_discount_error,
  update_discount,
  update_discount_success,
  update_discount_error,
} = discountReducerSlice.actions;

export default discountReducerSlice.reducer;
