import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_orders,
  get_orders_success,
  get_orders_error,
  filter_orders,
  filter_orders_success,
  filter_orders_error,
  search_orders,
  search_orders_success,
  search_orders_error,
  create_order,
  create_order_success,
  create_order_error,
  get_order,
  get_order_success,
  get_order_error,
  update_order_status,
  update_order_status_success,
  update_order_status_error,
  get_scanned_item,
  get_scanned_item_success,
  get_scanned_item_error,
} from "./reducers";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getOrdersSaga({ payload }) {
  try {
    const { page, limit = 10, id } = payload;
    const requestRes = yield call(
      api.get,
      `/api/v1/order/?page=${page}&perPage=${limit}&storeId=${id}`,
    );
    const response = requestRes.data;
    yield put({
      type: get_orders_success.type,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: get_orders_error.type,
    });
  }
}

function* filterOrdersSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/order/filter/?status=${payload}`,
    );
    const response = requestRes.data;
    yield put({
      type: filter_orders_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: filter_orders_error.type,
    });
  }
}

function* searchOrderSaga({ payload }) {
  try {
    const requestRes = yield call(api.get, `/api/v1/order?search=${payload}`);
    const response = requestRes.data;
    yield put({
      type: search_orders_success.type,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: search_orders_error.type,
    });
  }
}

function* createOrderSaga({ payload }) {
  try {
    const { data, handleReciept } = payload;
    const requestRes = yield call(api.post, `/api/v1/order`, data);
    const response = requestRes.data;
    yield put({
      type: create_order_success.type,
      payload: response,
    });
    toast.success(response.message);
    handleReciept();
    // setTimeout(() => {
    //   window.location.href = `/sales/orders`;
    // }, 1500);
  } catch (error) {
    toast.error(error?.response?.data?.data?.msg || error?.response?.data.msg);
    yield put({
      type: create_order_error.type,
    });
  }
}

function* getOrderSaga({ payload }) {
  try {
    const { id, store } = payload;
    const requestRes = yield call(
      api.get,
      `/api/v1/order/${id}?storeId=${store}`,
    );
    const response = requestRes.data;
    yield put({
      type: get_order_success.type,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: get_order_error.type,
    });
  }
}

function* updateOrderStatusSagas({ payload }) {
  try {
    const { data, id } = payload;
    const requestRes = yield call(
      api.patch,
      `/api/v1/order/status/${id}`,
      data,
    );
    const response = requestRes.data;
    console.log(response);
    yield put({
      type: update_order_status_success.type,
      payload: response.data,
    });
    if (response.status === true) {
      toast.success(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: update_order_status_error.type,
    });
  }
}

function* getScannedItem({ payload }) {
  try {
    const { barcode, store } = payload;
    const request = yield call(
      api.get,
      `/api/v1/product/search-barcode/?barcode=${barcode}&storeId=${store}`,
    );
    const res = request.data;
    yield put({
      type: get_scanned_item_success.type,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_scanned_item_error.type,
    });
  }
}

function* ordersSagas() {
  yield takeEvery(get_orders.type, getOrdersSaga);
  yield takeEvery(get_order.type, getOrderSaga);
  yield takeEvery(filter_orders.type, filterOrdersSaga);
  yield takeEvery(search_orders.type, searchOrderSaga);
  yield takeEvery(create_order.type, createOrderSaga);
  yield takeEvery(update_order_status.type, updateOrderStatusSagas);
  yield takeEvery(get_scanned_item.type, getScannedItem);
}

export default ordersSagas;
