import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_sandbox_secret,
  get_sandbox_secret_success,
  get_sandbox_secret_error,
  get_sandbox_public,
  get_sandbox_public_success,
  get_sandbox_public_error,
  get_live_public,
  get_live_public_success,
  get_live_public_error,
  get_live_secret,
  get_live_secret_success,
  get_live_secret_error,
} from "./reducers";
import api from "service/DataService";
import apis from "service/ProductionService";

function* getSandboxPublicSaga() {
  try {
    const request = yield call(
      api.get,
      `api/v1/developer/generate-keys?env=sandbox`,
    );
    const response = request.data;
    yield put({
      type: get_sandbox_public_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_sandbox_public_error.type,
    });
  }
}

function* getLivePublicSaga() {
  try {
    const request = yield call(
      apis.get,
      `api/v1/developer/generate-keys?env=production`,
    );
    const response = request.data;
    yield put({
      type: get_live_public_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_live_public_error.type,
    });
  }
}

function* getSandboxSecretSaga() {
  try {
    const request = yield call(
      api.get,
      `api/v1/developer/generate-keys?type=secret&=sandbox`,
    );
    const response = request.data;
    yield put({
      type: get_sandbox_secret_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_sandbox_secret_error.type,
    });
  }
}

function* getLiveSecretSaga() {
  try {
    const request = yield call(
      apis.get,
      `api/v1/developer/generate-keys?type=secret&=production`,
    );
    const response = request.data;
    yield put({
      type: get_live_secret_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_live_secret_error.type,
    });
  }
}

function* developerSagas() {
  yield takeEvery(get_sandbox_public.type, getSandboxPublicSaga);
  yield takeEvery(get_live_public.type, getLivePublicSaga);
  yield takeEvery(get_sandbox_secret.type, getSandboxSecretSaga);
  yield takeEvery(get_live_secret.type, getLiveSecretSaga);
}

export default developerSagas;
