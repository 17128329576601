import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_purchase_orders,
  get_purchase_orders_success,
  get_purchase_orders_error,
  create_purchase_order,
  create_purchase_order_success,
  create_purchase_order_error,
  get_single_purchase_order,
  get_single_purchase_order_success,
  get_single_purchase_order_error,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getPurchaseOrders({ payload }) {
  try {
    const { page = 1, limit = 10 } = payload;
    const request = yield call(
      api.get,
      `/api/v1/purchase_order?page=${page}&perPage=${limit}`,
    );
    const response = request.data;
    yield put({
      type: get_purchase_orders_success.type,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_purchase_orders_error.type,
    });
  }
}

function* createPurchaseOrders({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/purchase_order`, payload);
    const response = request.data;
    yield put({
      type: create_purchase_order_success.type,
      payload: response.purchase,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.href = "/purchase/orders";
    }, 1000);
  } catch (error) {
    console.log(error);
    yield put({
      type: create_purchase_order_error.type,
    });
  }
}

function* getSinglePurcahseOrder({ payload }) {
  try {
    const request = yield call(api.get, `/api/v1/purchase_order?${payload}`);
    const response = request.data;
    yield put({
      type: get_single_purchase_order_success.type,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_single_purchase_order_error.type,
    });
  }
}

function* purchaseOrdersSagas() {
  yield takeEvery(create_purchase_order.type, createPurchaseOrders);
  yield takeEvery(get_purchase_orders.type, getPurchaseOrders);
  yield takeEvery(get_single_purchase_order.type, getSinglePurcahseOrder);
}

export default purchaseOrdersSagas;
