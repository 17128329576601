import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_stores,
  get_stores_success,
  get_stores_error,
  create_store,
  create_store_success,
  create_store_error,
  publish_store,
  publish_store_success,
  publish_store_error,
  update_store_details,
  update_store_details_success,
  update_store_details_error,
  update_store_currency,
  update_store_currency_success,
  update_store_currency_error,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getStores() {
  try {
    const request = yield call(api.get, `/api/v1/stores`);
    const response = request.data;

    yield put({
      type: get_stores_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_stores_error.type,
    });
  }
}

function* createStores({ payload }) {
  try {
    const { data, onClose } = payload;
    const request = yield call(api.post, `/api/v1/add-branch`, data);
    const response = request.data;

    toast.success("Created New Store!!");
    yield put({
      type: create_store_success.type,
      payload: response,
    });
    setTimeout(() => {
      onClose();
    }, 1000);
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: create_store_error.type,
    });
  }
}

function* publishStoreSaga({ payload }) {
  try {
    const request = yield call(
      api.post,
      `/api/v1/stores/create-store`,
      payload,
    );
    const response = request.data;
    yield put({
      type: publish_store_success.type,
    });
    toast.success(response?.message);
  } catch (error) {
    toast.error(error?.response?.data?.Error);
    yield put({
      type: publish_store_error.type,
    });
  }
}

function* updateStoreDetailsSaga({ payload }) {
  try {
    const request = yield call(api.patch, `/api/v1/stores/details`, payload);

    const response = request.data;
    yield put({
      type: update_store_details_success.type,
    });
    toast.success(response.data);
    setTimeout(() => {
      window.location.href = "/store";
    }, 1000);
  } catch (error) {
    console.log(error);
    yield put({
      type: update_store_details_error.type,
    });
  }
}

function* updateStoreCurrencySaga({ payload }) {
  try {
    const request = yield call(api.patch, `/api/v1/stores/currency`, payload);
    const response = request.data;

    yield put({
      type: update_store_currency_success.type,
    });
    toast.success(response.data);
    setTimeout(() => {
      window.location.href = "/settings/payment";
    }, 1000);
  } catch (error) {
    console.log(error);
    yield put({
      type: update_store_currency_error.type,
    });
  }
}

function* storeSagas() {
  yield takeEvery(get_stores.type, getStores);
  yield takeEvery(create_store.type, createStores);
  yield takeEvery(publish_store.type, publishStoreSaga);
  yield takeEvery(update_store_details.type, updateStoreDetailsSaga);
  yield takeEvery(update_store_currency.type, updateStoreCurrencySaga);
}

export default storeSagas;
