import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LoadingUI from "components/loading";
import * as amplitude from "@amplitude/analytics-browser";

const Home = lazy(() => import("pages/home/Home"));
const Dashboard = lazy(() => import("pages/dashboard/Dashboard"));
const Customers = lazy(() => import("pages/customers/Customers"));
const Products = lazy(() => import("pages/products/Products"));
const Developers = lazy(() => import("pages/developers/Developers"));
const Settings = lazy(() => import("pages/settings/Settings"));
const Orders = lazy(() => import("pages/orders/Orders"));
const Invoice = lazy(() => import("pages/invoice"));
const Vendors = lazy(() => import("pages/vendors"));
const Report = lazy(() => import("pages/report/Report"));
const CustomerDetails = lazy(() =>
  import("pages/customerDetails/CustomerDetails"),
);
const SignUp = lazy(() => import("pages/auth/SignUp"));
const SignIn = lazy(() => import("pages/auth/SignIn"));
const CreateCustomer = lazy(() =>
  import("pages/customers/components/CreateCustomer"),
);
const OrderDetails = lazy(() => import("pages/orders/components/OrderDetails"));
const AddProducts = lazy(() => import("pages/products/AddProducts"));
const EditCustomer = lazy(() =>
  import("pages/customers/components/EditCustomer"),
);
const ForgotPassword = lazy(() => import("pages/auth/ForgotPassword"));
const EmailOTP = lazy(() => import("pages/auth/EmailOTP"));
const ResetPassword = lazy(() => import("pages/auth/ResetPassword"));
const Registration = lazy(() => import("pages/auth/components/Registration"));
const VerifyEmail = lazy(() => import("pages/auth/VerifyEmail"));
const Discounts = lazy(() => import("pages/discounts"));
const CreateDiscounts = lazy(() => import("pages/discounts/CreateDiscount"));
const GenerateOrder = lazy(() =>
  import("pages/orders/components/GenerateOrder"),
);
const Edit = lazy(() => import("pages/discounts/components/editDiscount"));
const Store = lazy(() => import("pages/store/index"));
const Product = lazy(() => import("pages/product/index"));
const CreateVendors = lazy(() => import("pages/vendors/create"));
const VendorsDetails = lazy(() => import("pages/vendors/VendorDetails"));
const Wallet = lazy(() => import("pages/wallet"));
const PurchaseOrders = lazy(() => import("pages/purchaseOrders"));
const SinglePurchaseOrders = lazy(() => import("pages/singlePurchaseOrder"));
const CreatePurchaseOrders = lazy(() => import("pages/purchaseOrders/create"));
const PaymentReceived = lazy(() => import("pages/paymentReceived"));
const PaymentReceivedSingle = lazy(() =>
  import("pages/paymentReceived/section/SingleDetails"),
);
const CreatePaymentReceived = lazy(() =>
  import("pages/paymentReceived/createReceived"),
);

const init_key = process.env.REACT_APP_AMPLITUDE_KEY;

const App = () => {
  useEffect(() => {
    amplitude.init(init_key);
  }, []);

  return (
    <Suspense fallback={<LoadingUI />}>
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-otp" element={<EmailOTP />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/user/reset-password/:id" element={<ResetPassword />} />
          <Route path="/user/verify_email/:id" element={<Registration />} />
          <Route path="/sales/customers" element={<Customers />} />
          <Route path="/inventory/products" element={<Products />} />
          <Route path="/inventory/products/:id" element={<Product />} />
          <Route path="/sales/orders" element={<Orders />} />
          <Route path="/sales/orders/generate" element={<GenerateOrder />} />
          <Route path="/sales/customers/:id" element={<CustomerDetails />} />
          <Route path="/sales/orders/details/:id" element={<OrderDetails />} />
          <Route path="/sales/customers/create" element={<CreateCustomer />} />
          <Route
            path="/purchase/payments-received/:id"
            element={<PaymentReceivedSingle />}
          />
          <Route
            path="/purchase/payments-received"
            element={<PaymentReceived />}
          />
          <Route
            path="/purchase/payments-received/create"
            element={<CreatePaymentReceived />}
          />
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/purchase/vendors" element={<Vendors />} />
          <Route
            path="/purchase/vendors/details/:id"
            element={<VendorsDetails />}
          />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/:id" element={<Settings />} />
          <Route path="/report" element={<Report />} />
          <Route path="/sales/customers/edit/:id" element={<EditCustomer />} />
          <Route path="/inventory/products/create" element={<AddProducts />} />
          <Route path="/sales/discounts" element={<Discounts />} />
          <Route path="/sales/discounts/create" element={<CreateDiscounts />} />
          <Route path="/sales/discounts/edit/:id" element={<Edit />} />
          <Route path="/store" element={<Store />} />
          <Route
            path="/customers/details/orders/:id"
            element={<OrderDetails />}
          />
          <Route path="/purchase/vendors/create" element={<CreateVendors />} />
          <Route
            path="/purchase/vendors/edit/:id"
            element={<CreateVendors />}
          />
          <Route path="/purchase/orders" element={<PurchaseOrders />} />
          <Route path="/purchase/create" element={<CreatePurchaseOrders />} />
          <Route
            path="/purchase/orders/:id"
            element={<SinglePurchaseOrders />}
          />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
