import { combineReducers } from "redux";
import authReducerSlice from "./auth/redux/reducer";
import customersReducerSlice from "./customers/redux/reducer";
import dashboardReducerSlice from "./dashboard/redux/reducers";
import developerReducerSlice from "./developers/redux/reducers";
import discountReducerSlice from "./discounts/redux/reducer";
import ordersReducerSlice from "./orders/redux/reducers";
import productsReducerSlice from "./products/redux/reducer";
import reportsReducerSlice from "./report/redux/reducers";
import settingsReducerSlice from "./settings/redux/reducer";
import storeReducerSlice from "./store/redux/reducer";
import vendorReducerSlice from "./vendors/redux/reducer";
import walletReducerSlice from "./wallet/redux/reducers";
import purchaseOrderReducerSlice from "./purchaseOrders/redux/reducer";
import paymentReceivedReducerSlice from "./paymentReceived/redux/reducer";

const rootReducers = combineReducers({
  auth: authReducerSlice,
  customers: customersReducerSlice,
  settings: settingsReducerSlice,
  products: productsReducerSlice,
  discount: discountReducerSlice,
  orders: ordersReducerSlice,
  developer: developerReducerSlice,
  report: reportsReducerSlice,
  store: storeReducerSlice,
  dashboard: dashboardReducerSlice,
  vendors: vendorReducerSlice,
  wallet: walletReducerSlice,
  purchase_orders: purchaseOrderReducerSlice,
  payment_received: paymentReceivedReducerSlice,
});

export default rootReducers;
