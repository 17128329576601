import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    creating: false,
  },
  data: [],
  single: {},
};

export const paymentReceivedReducerSlice = createSlice({
  name: "payment_received",
  initialState,
  reducers: {
    get_payment_received: (state) => {
      state.ui.loading = true;
    },
    get_payment_received_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload.data;
    },
    get_payment_received_error: (state) => {
      state.ui.loading = false;
    },
    create_payment_received: (state) => {
      state.ui.creating = true;
    },
    create_payment_received_success: (state) => {
      state.ui.creating = false;
    },
    create_payment_received_error: (state) => {
      state.ui.creating = false;
    },
    get_single_payment_received: (state) => {
      state.ui.loading = true;
    },
    get_single_payment_received_success: (state, { payload }) => {
      state.ui.loading = false;
      state.single = payload.data;
    },
    get_single_payment_received_error: (state) => {
      state.ui.loading = false;
    },
  },
});

export const {
  get_payment_received,
  get_payment_received_success,
  get_payment_received_error,
  create_payment_received,
  create_payment_received_success,
  create_payment_received_error,
  get_single_payment_received,
  get_single_payment_received_success,
  get_single_payment_received_error,
} = paymentReceivedReducerSlice.actions;

export default paymentReceivedReducerSlice.reducer;
