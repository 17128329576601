import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_payment_received,
  get_payment_received_success,
  get_payment_received_error,
  create_payment_received,
  create_payment_received_success,
  create_payment_received_error,
  get_single_payment_received,
  get_single_payment_received_success,
  get_single_payment_received_error,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getPaymentReceived(storeId) {
  try {
    const request = yield call(
      api.get,
      `/api/v1/payment_received/?storeId=${storeId}`,
    );
    const response = request.data;
    yield put({
      type: get_payment_received_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_payment_received_error.type,
    });
  }
}

function* createPaymentReceived({ payload }) {
  try {
    const request = yield call(
      api.post,
      `/api/v1/payment_received/add_payment`,
      payload,
    );
    const response = request.data;
    yield put({
      type: create_payment_received_success.type,
      payload: response,
    });
    toast.success(response.message);
    setTimeout(() => {
      window.location.href = "/purchase/payments-received";
    }, 1000);
  } catch (error) {
    yield put({
      type: create_payment_received_error.type,
    });
  }
}

function* getSinglePaymentReceivedSagas({ payload }) {
  try {
    const { id, storeId } = payload;
    const request = yield call(
      api.get,
      `/api/v1/payment_received/${id}?${storeId}`,
    );
    const response = request.data;
    console.log("This is single payment response", response);
    yield put({
      type: get_single_payment_received_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_single_payment_received_error.type,
    });
  }
}

function* paymentReceivedSagas() {
  yield takeEvery(create_payment_received.type, createPaymentReceived);
  yield takeEvery(get_payment_received.type, getPaymentReceived);
  yield takeEvery(
    get_single_payment_received.type,
    getSinglePaymentReceivedSagas,
  );
}

export default paymentReceivedSagas;
