import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_sales_by_item,
  get_sales_by_item_success,
  get_sales_by_item_error,
  get_sales_report,
  get_sales_report_success,
  get_sales_report_error,
  get_item_sales,
  get_item_sales_success,
  get_item_sales_error,
  get_transaction_orders,
  get_transaction_orders_success,
  get_transcation_orders_error,
  get_sales_transactions,
  get_sales_transactions_success,
  get_sales_transactions_error,
} from "./reducers";
import api from "service/DataService";
// import toast from "react-hot-toast";

function* getSalesByItem() {
  try {
    const request = yield call(api.get, `api/v1/sales_report/all`);
    const response = request.data;
    yield put({
      type: get_sales_by_item_success.type,
      payload: response,
    });
  } catch (error) {
    // toast.error(error.response.data.error);
    yield put({
      type: get_sales_by_item_error.type,
    });
  }
}

function* getSalesReport({ payload }) {
  try {
    const request = yield call(
      api.get,
      `api/v1/sales_report/filter/sales?type=${payload}`,
    );
    const response = request.data;
    yield put({
      type: get_sales_report_success.type,
      payload: response,
    });
  } catch (error) {
    // toast.error(error.response.data.error);
    yield put({
      type: get_sales_report_error.type,
    });
  }
}

function* getItemSales() {
  try {
    const request = yield call(api.get, `api/v1/sales_report/all`);
    const response = request.data;
    yield put({
      type: get_item_sales_success.type,
      payload: response,
    });
  } catch (error) {
    // toast.error(error.response.data.error);
    yield put({
      type: get_item_sales_error.type,
    });
  }
}

function* getTransctionsOrders() {
  try {
    const request = yield call(api.get, `api/v1/sales_transactions/orders`);
    const response = request.data;
    yield put({
      type: get_transaction_orders_success.type,
      payload: response.data,
    });
  } catch (error) {
    // toast.error(error.response.data.error);
    yield put({
      type: get_transcation_orders_error.type,
    });
  }
}

function* getSalesTransactions({ payload }) {
  try {
    const request = yield call(
      api.get,
      `api/v1/sales_transactions/filter?type=${payload}`,
    );
    const response = request.data;
    yield put({
      type: get_sales_transactions_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_sales_transactions_error.type,
    });
  }
}

function* reportSaga() {
  yield takeEvery(get_sales_by_item.type, getSalesByItem);
  yield takeEvery(get_sales_report.type, getSalesReport);
  yield takeEvery(get_item_sales.type, getItemSales);
  yield takeEvery(get_transaction_orders.type, getTransctionsOrders);
  yield takeEvery(get_sales_transactions.type, getSalesTransactions);
}

export default reportSaga;
