import React from "react";
import logo from "assets/blue-logo.png";
import { Box, Image } from "@chakra-ui/react";

const LoadingUI = () => {
  return (
    <Box display="grid" placeItems={"center"} w="100%" h="100vh">
      <Image src={logo} alt="logo" objectFit="cover" />
    </Box>
  );
};

export default LoadingUI;
