import { extendTheme } from "@chakra-ui/react";

export const Theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        backgroundColor: "#fff",
        fontFamily: "Satoshi",
        color: "#2B292F",
      },
      colors: {
        white: "#fff",
        gray: "#F9F9F9",
      },
      components: {
        Button: {
          variants: {
            normal: {
              backgroundColor: "#3F12C4",
              color: "#fff",
              _hover: {
                backgroundColor: "#6A3CEE",
              },
            },
          },
        },
      },
    }),
  },
});
