import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_wallet_details,
  get_wallet_details_error,
  get_wallet_details_success,
  create_wallet,
  create_wallet_success,
  create_wallet_error,
  get_user_transactions,
  get_user_transactions_success,
  get_user_transactions_error,
  validate_account,
  validate_account_error,
  validate_account_success,
  get_banks,
  get_banks_success,
  get_banks_error,
  transfer_to_banks,
  transfer_to_banks_success,
  transfer_to_banks_error,
} from "./reducers";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getWalletDetails() {
  try {
    const request = yield call(api.get, `/api/v1/wallet/details`);
    const response = request.data;
    yield put({
      type: get_wallet_details_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_wallet_details_error.type,
    });
  }
}

function* CreateSubWallet({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/wallet`, payload);
    const response = request.data;
    yield put({
      type: create_wallet_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: create_wallet_error.type,
    });
  }
}

function* getUserTransctions() {
  try {
    const request = yield call(api.get, `/api/v1/wallet/transactions`);
    const response = request.data;
    yield put({
      type: get_user_transactions_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.error);
    yield put({
      type: get_user_transactions_error.type,
    });
  }
}

function* validateUserAccount({ payload }) {
  try {
    const request = yield call(api.post, `api/v1/wallet/validate`, payload);
    const response = request.data;
    const result = response.details;
    yield put({
      type: validate_account_success.type,
      payload: result,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: validate_account_error.type,
    });
  }
}

function* getBanks() {
  try {
    const request = yield call(api.get, `api/v1/wallet/bankcode`);
    const response = request.data;
    yield put({
      type: get_banks_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_banks_error.type,
    });
  }
}

function* transferToBank({ payload }) {
  try {
    const request = yield call(api.post, `api/v1/wallet/transfer`, payload);
    const response = request.data;
    console.log(response);
    yield put({
      type: transfer_to_banks_success.type,
    });
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error);
    yield put({
      type: transfer_to_banks_error.type,
    });
  }
}

function* walletSagas() {
  yield takeEvery(get_wallet_details.type, getWalletDetails);
  yield takeEvery(get_user_transactions.type, getUserTransctions);
  yield takeEvery(create_wallet.type, CreateSubWallet);
  yield takeEvery(validate_account.type, validateUserAccount);
  yield takeEvery(get_banks.type, getBanks);
  yield takeEvery(transfer_to_banks.type, transferToBank);
}

export default walletSagas;
