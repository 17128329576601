import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    creating: false,
  },
  data: [],
  pagination: {},
  single: {},
};

export const purchaseOrderReducerSlice = createSlice({
  name: "purchase_orders",
  initialState,
  reducers: {
    get_purchase_orders: (state) => {
      state.ui.loading = true;
    },
    get_purchase_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data, pagination } = payload;
      state.data = data;
      state.pagination = pagination;
    },
    get_purchase_orders_error: (state) => {
      state.ui.loading = false;
    },
    create_purchase_order: (state) => {
      state.ui.creating = true;
    },
    create_purchase_order_success: (state) => {
      state.ui.creating = false;
    },
    create_purchase_order_error: (state) => {
      state.ui.creating = false;
    },
    get_single_purchase_order: (state) => {
      state.ui.loading = true;
    },
    get_single_purchase_order_success: (state, { payload }) => {
      state.ui.loading = false;
      state.single = payload.data[0];
    },
    get_single_purchase_order_error: (state) => {
      state.ui.loading = false;
    },
  },
});

export const {
  get_purchase_orders,
  get_purchase_orders_success,
  get_purchase_orders_error,
  create_purchase_order,
  create_purchase_order_success,
  create_purchase_order_error,
  get_single_purchase_order,
  get_single_purchase_order_success,
  get_single_purchase_order_error,
} = purchaseOrderReducerSlice.actions;

export default purchaseOrderReducerSlice.reducer;
