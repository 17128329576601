import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    creating: false,
    saving: false,
    loading_user: false,
  },
  members: [],
  audit_logs: [],
  audit_log: {},
  permissions: [],
  roles: [],
  user: {},
  subscriptions: [],
  plan: {},
  all_plans: [],
  banks: [],
  verified: {},
  store: {},
  shipping: [],
  tax: [],
  stores: [],
};

export const settingsReducerSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    get_user: (state) => {
      state.ui.loading_user = true;
    },
    get_user_success: (state, { payload }) => {
      state.ui.loading_user = false;

      const { result, userStore } = payload;
      state.user = result || payload;
      state.stores = userStore;
      state.store = userStore[0];
    },
    get_user_error: (state) => {
      state.ui.loading_user = false;
    },
    get_members: (state) => {
      state.ui.loading = true;
    },
    get_members_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.members = data;
    },
    get_members_error: (state) => {
      state.ui.loading = false;
    },
    add_member: (state) => {
      state.ui.creating = true;
    },
    add_member_success: (state, { payload }) => {
      state.ui.creating = false;
      state?.members?.unshift(payload[0]);
    },
    add_member_error: (state) => {
      state.ui.creating = false;
    },
    change_role: (state) => {
      state.ui.creating = true;
    },
    change_role_success: (state) => {
      state.ui.creating = false;
    },
    change_role_error: (state) => {
      state.ui.creating = false;
    },
    update_store_address: (state) => {
      state.ui.creating = true;
    },

    update_store_address_success: (state) => {
      state.ui.creating = false;
    },
    update_store_address_error: (state) => {
      state.ui.creating = false;
    },
    remove_member: (state) => {
      state.ui.loading = true;
    },
    remove_member_success: (state, { payload }) => {
      state.ui.loading = false;
      const response = state.members.filter((item) => item.id !== payload);
      state.members = response;
    },
    remove_member_error: (state) => {
      state.ui.loading = false;
    },
    get_all_audit_logs: (state) => {
      state.ui.loading = true;
    },
    get_all_audit_logs_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.audit_logs = data;
    },
    get_all_audit_logs_error: (state) => {
      state.ui.loading = false;
    },
    search_audit_logs: (state) => {
      state.ui.loading = true;
    },
    search_audit_logs_success: (state, { payload }) => {
      state.ui.loading = false;
      state.audit_logs = payload;
    },
    search_audit_logs_error: (state) => {
      state.ui.loading = false;
    },
    get_audit_log: (state) => {
      state.ui.loading = true;
    },
    get_audit_log_success: (state, { payload }) => {
      state.ui.loading = false;
      state.audit_log = payload;
    },
    get_audit_log_error: (state) => {
      state.ui.loading = false;
    },
    get_permissions: (state) => {
      state.ui.loading = true;
    },
    get_permissions_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.permissions = data;
    },
    get_permissions_error: (state) => {
      state.ui.loading = false;
    },
    create_role: (state) => {
      state.ui.creating = true;
    },
    create_role_success: (state) => {
      state.ui.creating = false;
    },
    create_role_error: (state) => {
      state.ui.creating = false;
    },
    get_roles: (state) => {
      state.ui.loading = true;
    },
    get_roles_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.roles = data;
    },
    get_roles_error: (state) => {
      state.ui.loading = false;
    },
    update_user_details: (state) => {
      state.ui.creating = true;
    },
    update_user_details_success: (state) => {
      state.ui.creating = false;
    },
    update_user_details_error: (state) => {
      state.ui.creating = false;
    },
    change_user_password: (state) => {
      state.ui.creating = true;
    },
    change_user_password_success: (state) => {
      state.ui.creating = false;
    },
    change_user_password_error: (state) => {
      state.ui.creating = false;
    },
    update_billing_contact: (state) => {
      state.ui.creating = true;
    },
    update_billing_contact_success: (state) => {
      state.ui.creating = false;
    },
    update_billing_contact_error: (state) => {
      state.ui.creating = false;
    },
    update_user_website: (state) => {
      state.ui.creating = true;
    },
    update_user_website_success: (state) => {
      state.ui.creating = false;
    },
    update_user_website_error: (state) => {
      state.ui.creating = false;
    },
    get_subscriptions: (state) => {
      state.ui.loading = true;
    },
    get_subscriptions_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.subscriptions = data;
    },
    get_subscriptions_error: (state) => {
      state.ui.loading = false;
    },
    subscribe: (state) => {
      state.ui.creating = true;
    },
    subscribe_success: (state, { payload }) => {
      state.ui.creating = false;
    },
    subscribe_error: (state) => {
      state.ui.creating = false;
    },
    get_plan: (state) => {
      state.ui.loading = true;
    },
    get_plan_success: (state, { payload }) => {
      state.ui.loading = false;
      state.plan = payload;
    },
    get_plan_error: (state) => {
      state.ui.loading = false;
    },
    change_personal_image: (state) => {
      state.ui.saving = true;
    },
    change_personal_image_success: (state) => {
      state.ui.saving = false;
    },
    change_personal_image_error: (state) => {
      state.ui.saving = false;
    },
    change_brand_image: (state) => {
      state.ui.saving = true;
    },
    change_brand_image_success: (state) => {
      state.ui.saving = false;
    },
    change_brand_image_error: (state) => {
      state.ui.saving = false;
    },
    get_all_user_plan: (state) => {
      state.ui.loading = true;
    },
    get_all_user_plan_success: (state, { payload }) => {
      state.ui.loading = false;
      state.all_plans = payload;
    },
    get_all_user_plan_error: (state) => {
      state.ui.loading = false;
    },
    get_banks: (state) => {
      state.ui.loading = true;
    },
    get_banks_success: (state, { payload }) => {
      state.ui.loading = false;
      state.banks = payload;
    },
    get_banks_error: (state) => {
      state.ui.loading = false;
    },
    verify_account: (state) => {
      state.ui.creating = true;
    },
    verify_account_success: (state, { payload }) => {
      state.ui.creating = false;
      state.verified = payload;
    },
    verify_account_error: (state) => {
      state.ui.creating = false;
    },
    create_shipping: (state) => {
      state.ui.creating = true;
    },
    create_shipping_success: (state) => {
      state.ui.creating = false;
    },
    create_shipping_error: (state) => {
      state.ui.creating = false;
    },
    get_shipping: (state) => {
      state.ui.loading = true;
    },
    get_shipping_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.shipping = data;
    },
    get_shipping_error: (state) => {
      state.ui.loading = false;
    },
    update_shipping: (state) => {
      state.ui.creating = true;
    },
    update_shipping_success: (state) => {
      state.ui.creating = false;
    },
    update_shipping_error: (state) => {
      state.ui.creating = false;
    },
    delete_shipping: (state) => {
      state.ui.creating = true;
    },
    delete_shipping_success: (state) => {
      state.ui.creating = false;
    },
    delete_shipping_error: (state) => {
      state.ui.creating = false;
    },
    create_tax: (state) => {
      state.ui.creating = true;
    },
    create_tax_success: (state) => {
      state.ui.creating = false;
    },
    create_tax_error: (state) => {
      state.ui.creating = false;
    },
    get_tax: (state) => {
      state.ui.loading = true;
    },
    get_tax_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.tax = data;
    },
    get_tax_error: (state) => {
      state.ui.loading = false;
    },
    update_tax: (state) => {
      state.ui.creating = true;
    },
    update_tax_success: (state) => {
      state.ui.creating = false;
    },
    update_tax_error: (state) => {
      state.ui.creating = false;
    },
    delete_tax: (state) => {
      state.ui.creating = true;
    },
    delete_tax_success: (state) => {
      state.ui.creating = false;
    },
    delete_tax_error: (state) => {
      state.ui.creating = false;
    },
  },
});

export const {
  get_user,
  get_user_success,
  get_user_error,
  get_members,
  get_members_success,
  get_members_error,
  add_member,
  add_member_success,
  add_member_error,
  change_role,
  change_role_success,
  change_role_error,
  remove_member,
  remove_member_success,
  remove_member_error,
  get_all_audit_logs,
  get_all_audit_logs_success,
  get_all_audit_logs_error,
  get_audit_log,
  get_audit_log_success,
  get_audit_log_error,
  get_permissions,
  get_permissions_success,
  get_permissions_error,
  create_role,
  create_role_success,
  create_role_error,
  search_audit_logs,
  search_audit_logs_success,
  search_audit_logs_error,
  get_roles,
  get_roles_success,
  get_roles_error,
  update_user_details,
  update_user_details_success,
  update_user_details_error,
  change_user_password,
  change_user_password_success,
  change_user_password_error,
  update_billing_contact,
  update_billing_contact_success,
  update_billing_contact_error,
  update_store_address,
  update_store_address_success,
  update_store_address_error,

  update_user_website,
  update_user_website_success,
  update_user_website_error,
  get_subscriptions,
  get_subscriptions_success,
  get_subscriptions_error,
  subscribe,
  subscribe_success,
  subscribe_error,
  get_plan,
  get_plan_success,
  get_plan_error,
  change_personal_image,
  change_personal_image_success,
  change_personal_image_error,
  change_brand_image,
  change_brand_image_success,
  change_brand_image_error,
  get_all_user_plan,
  get_all_user_plan_success,
  get_all_user_plan_error,
  get_banks,
  get_banks_success,
  get_banks_error,
  verify_account,
  verify_account_success,
  verify_account_error,
  create_shipping,
  create_shipping_success,
  create_shipping_error,
  get_shipping,
  get_shipping_success,
  get_shipping_error,
  update_shipping,
  update_shipping_success,
  update_shipping_error,
  create_tax,
  create_tax_success,
  create_tax_error,
  get_tax,
  get_tax_success,
  get_tax_error,
  update_tax,
  update_tax_success,
  update_tax_error,
  delete_tax,
  delete_tax_success,
  delete_tax_error,
  delete_shipping,
  delete_shipping_success,
  delete_shipping_error,
} = settingsReducerSlice.actions;

export default settingsReducerSlice.reducer;
