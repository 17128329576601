import { put, takeEvery, takeLatest, call } from "@redux-saga/core/effects";
import {
  get_customers_success,
  get_customers_error,
  get_customers,
  create_customer_success,
  create_customer_error,
  create_customer,
  get_customer_success,
  get_customer_error,
  get_customer,
  edit_customer_success,
  edit_customer_error,
  edit_customer,
  block_customer_success,
  block_customer_error,
  block_customer,
  get_search_success,
  get_search_error,
  get_search,
  filter_customer_success,
  filter_customer_error,
  filter_customer,
  auto_generate_success,
  auto_generate_error,
  auto_generate,
  enable_customer,
  enable_customer_success,
  enable_customer_error,
  get_customer_order,
  get_customer_order_success,
  get_customer_order_error,
  upload_customers,
  upload_customers_error,
  upload_customers_success,
} from "./reducer";
import api from "service/DataService";
import toast from "react-hot-toast";

function* getCustomerSaga({ payload }) {
  try {
    const { page, limit, id } = payload;
    const requestRes = yield call(
      api.get,
      `/api/v1/customers/paginated-results?page=${page}&perPage=${limit}&storeId=${id}`,
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_customers_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_customers_error.type,
    });
  }
}

function* getSearchSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/customers/search/name/?first=${payload}`,
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_search_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_search_error.type,
    });
  }
}

function* getFilterSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/customers/filter-customer/${payload}`,
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: filter_customer_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: filter_customer_error.type,
    });
  }
}

function* generateCustomerSaga() {
  try {
    const requestRes = yield call(api.post, `/api/v1/customers/auto-create`);
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success("Customer generated successfully");
    yield put({
      type: auto_generate_success.type,
      payload: response,
    });
  } catch (error) {
    toast.error(error?.response?.data?.msg);
    yield put({
      type: auto_generate_error.type,
    });
  }
}

function* getSingleCustomerSaga({ payload }) {
  try {
    const { id, store } = payload;
    const requestRes = yield call(
      api.get,
      `/api/v1/customers/${id}?storeId=${store}`,
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    yield put({
      type: get_customer_success.type,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: get_customer_error.type,
    });
  }
}

function* createCustomerSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.post,
      `/api/v1/customers/create`,
      payload,
    );
    const responseData = requestRes.data;
    const response = responseData.data;

    if (responseData.success === false) {
      toast.error(responseData.message);
      yield put({
        type: create_customer_error.type,
      });
    } else {
      toast.success("Customer added succefully", {
        style: {
          background: "#10AA69",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "500",
          height: "64px",
        },
      });
      if (window.location.pathname === "/sales/customers/create") {
        setTimeout(() => {
          window.location.href = "/sales/customers";
        }, 1000);
      }
      yield put({
        type: create_customer_success.type,
        payload: response,
      });
    }
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: create_customer_error.type,
    });
  }
}

function* editCustomerSaga({ payload }) {
  try {
    const { id } = payload;
    delete payload.id;
    const requestRes = yield call(
      api.patch,
      `/api/v1/customers/update/${id}`,
      payload,
    );
    const responseData = requestRes.data;
    const response = responseData.data;

    toast.success(response || "Customer updated successfully!!", {
      style: {
        background: "#10AA69",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
    setTimeout(() => {
      window.location.href = `/sales/customers/${id}`;
    }, 2000);
    yield put({
      type: edit_customer_success.type,
      payload: response,
    });
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: edit_customer_error.type,
    });
  }
}

function* blockCustomerSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/customers/block/${payload}`,
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.error(response, {
      style: {
        background: "#F13E3E",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
    setTimeout(() => {
      window.location.href = `/sales/customers/${payload}`;
    }, 1000);
    yield put({
      type: block_customer_success.type,
      payload: response,
    });
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: block_customer_error.type,
    });
  }
}

function* enableCustomerSaga({ payload }) {
  try {
    const requestRes = yield call(
      api.get,
      `/api/v1/customers/unblock/${payload}`,
    );
    const responseData = requestRes.data;
    const response = responseData.data;
    toast.success(response, {
      style: {
        background: "#10AA69",
        borderRadius: "10px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "500",
        height: "64px",
      },
    });
    setTimeout(() => {
      window.location.href = `/sales/customers/${payload}`;
    }, 1000);
    yield put({
      type: enable_customer_success.type,
      payload: response,
    });
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.error || response?.data?.msg);
    yield put({
      type: enable_customer_error.type,
    });
  }
}

function* getCustomerOrderSagas({ payload }) {
  try {
    const request = yield call(api.get, `/api/v1/order/customer/${payload}`);
    const response = request.data;
    yield put({
      type: get_customer_order_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_customer_order_error.type,
    });
  }
}

function* uploadCustomers({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/customers/upload`, payload);
    const response = request.data;
    toast.success(response.message);
    yield put({
      type: upload_customers_success.type,
    });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (error) {
    console.log(error);
    toast.err(error?.response?.data?.message);
    yield put({
      type: upload_customers_error.type,
    });
  }
}

function* customerSagas() {
  yield takeEvery(get_customers.type, getCustomerSaga);
  yield takeEvery(get_customer.type, getSingleCustomerSaga);
  yield takeLatest(create_customer.type, createCustomerSaga);
  yield takeLatest(edit_customer.type, editCustomerSaga);
  yield takeLatest(block_customer.type, blockCustomerSaga);
  yield takeLatest(get_search.type, getSearchSaga);
  yield takeLatest(filter_customer.type, getFilterSaga);
  yield takeLatest(auto_generate.type, generateCustomerSaga);
  yield takeLatest(enable_customer.type, enableCustomerSaga);
  yield takeLatest(get_customer_order.type, getCustomerOrderSagas);
  yield takeLatest(upload_customers.type, uploadCustomers);
}

export default customerSagas;
