import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    createLoading: false,
  },
  customers: [],
  pagination: {},
  customer: {},
  customer_order: [],
};

export const customersReducerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    get_customers_success: (state, { payload }) => {
      const { data, pagination } = payload;
      state.ui.loading = false;
      state.customers = data;
      state.pagination = pagination;
    },
    get_customers_error: (state) => {
      state.ui.loading = false;
    },
    get_customers: (state) => {
      state.ui.loading = true;
    },
    create_customer_success: (state, { payload }) => {
      state.ui.createLoading = false;
      state?.customers?.unshift(payload[0]);
    },
    create_customer_error: (state) => {
      state.ui.createLoading = false;
    },
    create_customer: (state) => {
      state.ui.createLoading = true;
    },
    get_customer_success: (state, { payload }) => {
      state.ui.loading = false;
      state.customer = payload;
    },
    get_customer_error: (state) => {
      state.ui.loading = false;
    },
    get_customer: (state) => {
      state.ui.loading = true;
    },
    edit_customer_success: (state, { payload }) => {
      state.ui.createLoading = false;
      state.customer = payload;
    },
    edit_customer_error: (state) => {
      state.ui.createLoading = false;
    },
    edit_customer: (state) => {
      state.ui.createLoading = true;
    },
    block_customer_success: (state) => {
      state.ui.createLoading = false;
    },
    block_customer_error: (state) => {
      state.ui.createLoading = false;
    },
    block_customer: (state) => {
      state.ui.createLoading = true;
    },
    enable_customer: (state) => {
      state.ui.createLoading = true;
    },
    enable_customer_success: (state) => {
      state.ui.createLoading = false;
    },
    enable_customer_error: (state) => {
      state.ui.createLoading = false;
    },
    get_search_success: (state, { payload }) => {
      state.ui.loading = false;
      state.customers = payload;
    },
    get_search_error: (state) => {
      state.ui.loading = false;
    },
    get_search: (state) => {
      state.ui.loading = true;
    },
    filter_customer_success: (state, { payload }) => {
      state.ui.loading = false;
      state.customers = payload;
    },
    filter_customer_error: (state) => {
      state.ui.loading = false;
    },
    filter_customer: (state) => {
      state.ui.loading = true;
    },
    auto_generate_success: (state) => {
      state.ui.createLoading = false;
    },
    auto_generate_error: (state) => {
      state.ui.createLoading = false;
    },
    auto_generate: (state) => {
      state.ui.loading = true;
    },
    get_customer_order: (state) => {
      state.ui.loading = true;
    },
    get_customer_order_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.customer_order = data;
    },
    get_customer_order_error: (state) => {
      state.ui.loading = false;
    },
    upload_customers: (state) => {
      state.ui.createLoading = true;
    },
    upload_customers_success: (state) => {
      state.ui.createLoading = false;
    },
    upload_customers_error: (state) => {
      state.ui.createLoading = false;
    },
  },
});

export const {
  get_customers_success,
  get_customers_error,
  get_customers,
  create_customer_success,
  create_customer_error,
  create_customer,
  get_customer_success,
  get_customer_error,
  get_customer,
  edit_customer_success,
  edit_customer_error,
  edit_customer,
  block_customer_success,
  block_customer_error,
  block_customer,
  get_search_success,
  get_search_error,
  get_search,
  filter_customer_success,
  filter_customer_error,
  filter_customer,
  auto_generate_success,
  auto_generate_error,
  auto_generate,
  enable_customer,
  enable_customer_success,
  enable_customer_error,
  get_customer_order,
  get_customer_order_success,
  get_customer_order_error,
  upload_customers,
  upload_customers_error,
  upload_customers_success,
} = customersReducerSlice.actions;

export default customersReducerSlice.reducer;
