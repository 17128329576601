import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
  },
  sandbox_public: {},
  sandbox_secret: {},
  live_public: {},
  live_secret: {},
};

export const developerReducerSlice = createSlice({
  name: "developer",
  initialState,
  reducers: {
    get_sandbox_secret: (state) => {
      state.ui.loading = true;
    },
    get_sandbox_secret_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.sandbox_secret = data;
    },
    get_sandbox_secret_error: (state) => {
      state.ui.loading = false;
    },
    get_sandbox_public: (state) => {
      state.ui.loading = true;
    },
    get_sandbox_public_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.sandbox_public = data;
    },
    get_sandbox_public_error: (state) => {
      state.ui.loading = false;
    },
    get_live_public: (state) => {
      state.ui.loading = true;
    },
    get_live_public_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.live_public = data;
    },
    get_live_public_error: (state) => {
      state.ui.loading = false;
    },
    get_live_secret: (state) => {
      state.ui.loading = true;
    },
    get_live_secret_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.live_secret = data;
    },
    get_live_secret_error: (state) => {
      state.ui.loading = false;
    },
  },
});

export const {
  get_sandbox_secret,
  get_sandbox_secret_success,
  get_sandbox_secret_error,
  get_sandbox_public,
  get_sandbox_public_success,
  get_sandbox_public_error,
  get_live_public,
  get_live_public_success,
  get_live_public_error,
  get_live_secret,
  get_live_secret_success,
  get_live_secret_error,
} = developerReducerSlice.actions;

export default developerReducerSlice.reducer;
