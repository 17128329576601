import { put, takeEvery, call, takeLatest } from "@redux-saga/core/effects";
import {
  get_products,
  get_products_success,
  get_products_error,
  auto_create_products,
  auto_create_products_success,
  auto_create_products_error,
  search_products,
  search_products_success,
  search_products_error,
  create_products,
  create_products_success,
  create_products_error,
  get_single_product,
  get_single_product_success,
  get_single_product_error,
  update_product,
  update_product_success,
  update_product_error,
  delete_product,
  delete_product_success,
  delete_product_error,
  add_product_category,
  add_product_category_success,
  add_product_category_error,
  get_product_category,
  get_product_category_success,
  get_product_category_error,
  bulk_upload_products,
  bulk_upload_products_error,
  bulk_upload_products_success,
} from "./reducer";

import api from "service/DataService";
import toast from "react-hot-toast";

function* getProductsSaga({ payload }) {
  try {
    const { page, limit, id } = payload;
    const request = yield call(
      api.get,
      `/api/v1/product/paginated/results/?page=${page}&perPage=${limit}&storeId=${id}`,
    );
    const response = request.data;
    if (response && response.data && response.data.data) {
      const result = response.data;
      yield put({
        type: get_products_success.type,
        payload: result,
      });
    }
  } catch (erorr) {
    console.log(erorr);
    yield put({
      type: get_products_error.type,
    });
  }
}

function* autoCreateProductsSaga() {
  try {
    const request = yield call(api.get, `/api/v1/product/auto-create`);
    const response = request.data;
    yield put({
      type: auto_create_products_success.type,
      payload: response,
    });
  } catch (error) {
    toast.error(error?.response?.data?.msg);
    yield put({
      type: auto_create_products_error.type,
    });
  }
}

function* searchProductsSaga({ payload }) {
  try {
    const { debouncedTerm, id } = payload;
    const request = yield call(
      api.get,
      `/api/v1/product/search/?name=${debouncedTerm}&storeId=${id}`,
    );
    const response = request.data;
    yield put({
      type: search_products_success.type,
      payload: response,
    });
  } catch (erorr) {
    console.log(erorr);
    yield put({
      type: search_products_error.type,
    });
  }
}

function* createProductSaga({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/product/create`, payload);
    const response = request.data;
    yield put({
      type: create_products_success.type,
    });

    if (response.success === false) {
      toast.error(response.message);
    } else {
      toast.success(response.message, {
        style: {
          background: "#10AA69",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "500",
          height: "64px",
        },
      });
      setTimeout(() => {
        window.location.href = "/inventory/products";
      }, 1000);
    }
  } catch (error) {
    const { response } = error;
    toast.error(response?.data?.msg);
    yield put({
      type: create_products_error.type,
    });
  }
}

function* getSingleProductsSaga({ payload }) {
  try {
    const request = yield call(api.get, `/api/v1/product/${payload}`);
    const response = request.data;
    yield put({
      type: get_single_product_success.type,
      payload: response.data,
    });
  } catch (erorr) {
    console.log(erorr);
    yield put({
      type: get_single_product_error.type,
    });
  }
}

function* UpdateProductSagas({ payload }) {
  try {
    const { id, data, storeId } = payload;
    const request = yield call(
      api.put,
      `/api/v1/product/${id}?storeId=${storeId}`,
      data,
    );
    const response = request.data;

    yield put({
      type: update_product_success.type,
      payload: response.data,
    });
    toast.success(response.data);
    setTimeout(() => {
      window.location.href = "/inventory/products";
    }, 1500);
  } catch (error) {
    toast.error(error?.response?.data?.msg);
    yield put({
      type: update_product_error.type,
    });
  }
}

function* removeProductSagas({ payload }) {
  try {
    const { product, id } = payload;
    const request = yield call(
      api.delete,
      `/api/v1/product/${product}?storeId=${id}`,
    );
    const response = request.data;
    console.log(response);
    yield put({
      type: delete_product_success.type,
    });
    toast.success("Product Deleted!!");
    setTimeout(() => {
      window.location.href = "/inventory/products";
    }, 1000);
  } catch (err) {
    toast.error(err?.response?.data?.msg);
    yield put({
      type: delete_product_error.type,
    });
  }
}

function* addProductCategory({ payload }) {
  try {
    const { data, close } = payload;
    const request = yield call(api.post, `/api/v1/product/category`, data);
    const response = request.data;
    toast.success(response.message);
    yield put({
      type: add_product_category_success.type,
      payload: response.data,
    });
    setTimeout(() => {
      close();
    }, 1000);
  } catch (err) {
    toast.error(err?.response?.data?.msg);
    yield put({
      type: add_product_category_error.type,
    });
  }
}

function* getCategoriesSagas() {
  try {
    const request = yield call(api.get, `/api/v1/product/categories`);
    const response = request.data;
    yield put({
      type: get_product_category_success.type,
      payload: response.data,
    });
  } catch (err) {
    toast.error(err?.response?.data?.msg);
    yield put({
      type: get_product_category_error.type,
    });
  }
}

function* bulkUploadProducts({ payload }) {
  try {
    const request = yield call(api.post, `/api/v1/product/upload`, payload);
    const response = request.data;
    toast.success(response.message);
    yield put({
      type: bulk_upload_products_success.type,
    });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (error) {
    console.log(error);
    yield put({
      type: bulk_upload_products_error.type,
    });
  }
}

function* productsSagas() {
  yield takeEvery(get_products.type, getProductsSaga);
  yield takeEvery(auto_create_products.type, autoCreateProductsSaga);
  yield takeLatest(search_products.type, searchProductsSaga);
  yield takeLatest(create_products.type, createProductSaga);
  yield takeLatest(get_single_product.type, getSingleProductsSaga);
  yield takeLatest(update_product.type, UpdateProductSagas);
  yield takeLatest(delete_product.type, removeProductSagas);
  yield takeLatest(add_product_category.type, addProductCategory);
  yield takeLatest(get_product_category.type, getCategoriesSagas);
  yield takeLatest(bulk_upload_products.type, bulkUploadProducts);
}

export default productsSagas;
