import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    filtering: false,
  },
  top_customers: [],
  top_products: [],
  recent_orders: [],
  total_sales: null,
  today_sales: null,
  total_customers: null,
  total_orders: null,
  sales_filter: [],
};

export const dashboardReducerSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    get_top_customers: (state) => {
      state.ui.loading = true;
    },
    get_top_customers_success: (state, { payload }) => {
      state.ui.loading = false;
      state.top_customers = payload;
    },
    get_top_customers_error: (state) => {
      state.ui.loading = false;
    },
    get_top_products: (state) => {
      state.ui.loading = true;
    },
    get_top_products_success: (state, { payload }) => {
      state.ui.loading = false;
      state.top_products = payload;
    },
    get_top_products_error: (state) => {
      state.ui.loading = false;
    },
    get_recent_orders: (state) => {
      state.ui.loading = true;
    },
    get_recent_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      state.recent_orders = payload;
    },
    get_recent_orders_error: (state) => {
      state.ui.loading = false;
    },
    get_total_sales: (state) => {
      state.ui.loading = true;
    },
    get_total_sales_success: (state, { payload }) => {
      state.ui.loading = false;
      state.total_sales = payload;
    },
    get_total_sales_error: (state) => {
      state.ui.loading = false;
    },
    get_today_sales: (state) => {
      state.ui.loading = true;
    },
    get_today_sales_success: (state, { payload }) => {
      state.ui.loading = false;
      state.today_sales = payload;
    },
    get_today_sales_error: (state) => {
      state.ui.loading = false;
    },
    get_total_customers: (state) => {
      state.ui.loading = true;
    },
    get_total_customers_success: (state, { payload }) => {
      state.ui.loading = false;
      state.total_customers = payload;
    },
    get_total_customers_error: (state) => {
      state.ui.loading = false;
    },
    get_total_orders: (state) => {
      state.ui.loading = true;
    },
    get_total_orders_success: (state, { payload }) => {
      state.ui.loading = false;
      state.total_orders = payload;
    },
    get_total_orders_error: (state) => {
      state.ui.loading = false;
    },
    get_sales_filter: (state) => {
      state.ui.filtering = true;
    },
    get_sales_filter_success: (state, { payload }) => {
      state.ui.filtering = false;
      state.sales_filter = payload;
    },
    get_sales_filter_error: (state) => {
      state.ui.filtering = false;
    },
  },
});

export const {
  get_top_customers,
  get_top_customers_success,
  get_top_customers_error,
  get_top_products,
  get_top_products_success,
  get_top_products_error,
  get_total_sales,
  get_total_sales_success,
  get_total_sales_error,
  get_today_sales,
  get_today_sales_success,
  get_today_sales_error,
  get_total_customers,
  get_total_customers_success,
  get_total_customers_error,
  get_total_orders,
  get_total_orders_success,
  get_total_orders_error,
  get_recent_orders,
  get_recent_orders_success,
  get_recent_orders_error,
  get_sales_filter,
  get_sales_filter_success,
  get_sales_filter_error,
} = dashboardReducerSlice.actions;

export default dashboardReducerSlice.reducer;
